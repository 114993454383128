/* roboto-regular - latin-ext_latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: url("../../assets/fonts/roboto-v15-latin-ext_latin-regular.eot");
	/* IE9 Compat Modes */
	src: local("Roboto"), local("Roboto"),
		url("../../assets/fonts/roboto-v15-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"),
		/* IE6-IE8 */
		url("../../assets/fonts/roboto-v15-latin-ext_latin-regular.woff2") format("woff2"),
		/* Super Modern Browsers */
		url("../../assets/fonts/roboto-v15-latin-ext_latin-regular.woff") format("woff"),
		/* Modern Browsers */
		url("../../assets/fonts/roboto-v15-latin-ext_latin-regular.ttf") format("truetype"),
		/* Safari, Android, iOS */
		url("../../assets/fonts/roboto-v15-latin-ext_latin-regular.svg") format("svg");
}

/* roboto-500 - latin-ext_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('../../assets/fonts/roboto-v15-latin-ext_latin-500.eot');
	/* IE9 Compat Modes */
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url('../../assets/fonts/roboto-v15-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('../../assets/fonts/roboto-v15-latin-ext_latin-500.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('../../assets/fonts/roboto-v15-latin-ext_latin-500.woff') format('woff'),
		/* Modern Browsers */
		url('../../assets/fonts/roboto-v15-latin-ext_latin-500.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('../../assets/fonts/roboto-v15-latin-ext_latin-500.svg#Roboto') format('svg');
	/* Legacy iOS */
}

/* Days One - Regular */
@font-face {
	font-family: 'Days One';
	src: url('../../assets/fonts/DaysOne-Regular.eot');
	src: local('Days One'),
		url('../../assets/fonts/DaysOne-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/DaysOne-Regular.woff2') format('woff2'),
		url('../../assets/fonts/DaysOne-Regular.woff') format('woff'),
		url('../../assets/fonts/DaysOne-Regular.svg#DaysOne-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* OpenSans - Light */
@font-face {
	font-family: 'Open Sans';
	src: url('../../assets/fonts/OpenSans-Light.eot');
	src: local('Open Sans'),
		url('../../assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/OpenSans-Light.woff2') format('woff2'),
		url('../../assets/fonts/OpenSans-Light.woff') format('woff'),
		url('../../assets/fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

/* OpenSans - ExtraBold */
@font-face {
	font-family: 'Open Sans';
	src: url('../../assets/fonts/OpenSans-ExtraBold.eot');
	src: local('Open Sans'),
		url('../../assets/fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
		url('../../assets/fonts/OpenSans-ExtraBold.woff') format('woff'),
		url('../../assets/fonts/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

* {
	font-family: "Open Sans";
	font-weight: 300;
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 56px;
	padding: 0;
	color: white;
	background: #32404e;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	z-index: 50;
	padding: 0 8px;
}

.header h1 {
	float: left;
	margin: 0;
	padding: 0 15px;
	font-family: "Roboto";
	font-size: 24px;
	line-height: 56px;
	font-weight: 400;
	color: #fff;
}

.header nav {
	float: right;
	font-size: 100%;
	display: flex;
	margin-left: auto;
	margin-right: 0px;
}
